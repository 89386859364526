import React, { useState } from "react";

const Contador = (props) => {
  const parts = props.fecha.split("/");

  const day = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10) - 1; // Months in JavaScript are zero-based (0-11)
  const year = parseInt(parts[2], 10);

  let fecha_referencia = new Date(year, month, day);

  function getDateDifference(desde, hasta) {
    let difference = hasta - desde;

    if (difference < 0) {
      // Swap the values of hasta and desde
      const temp = hasta;
      hasta = desde;
      desde = temp;
    }

    difference = hasta - desde;

    const yearDiff = hasta.getFullYear() - desde.getFullYear();
    const monthDiff = hasta.getMonth() - desde.getMonth();
    const dayDiff = hasta.getDate() - desde.getDate();

    let years = yearDiff;
    let months = monthDiff;
    let days = dayDiff;

    if (dayDiff < 0) {
      months -= 1;
      const lastMonth = new Date(hasta.getFullYear(), hasta.getMonth(), 0).getDate();
      days = lastMonth - desde.getDate() + hasta.getDate();
    }

    if (monthDiff < 0) {
      years -= 1;
      months = 12 + monthDiff;
    }

    // Calculate weeks
    const weeks = Math.floor(difference / (1000 * 60 * 60 * 24 * 7));

    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        Años: years,
        Meses: months,
        Días: days,
        Semanas: weeks
      };
    }

    return timeLeft;
  }

  const [timeLeft, setTimeLeft] = useState(
    getDateDifference(fecha_referencia, new Date())
  );
  const [semanas, setSemanas] = useState(
    getDateDifference(fecha_referencia, new Date()).Semanas
  );

  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }
    if (interval !== "Semanas") {
      timerComponents.push(
        <span>
          {timeLeft[interval]} {interval}{" "}
        </span>
      );
    }

  });

  return (
    <>
      <div
        className="alert alert-info m-1 etiqueta-info"
        style={{ padding: "3px", width: "100%" }}
      >
        {props.nombre}: ({props.fecha}) {timerComponents.length ? timerComponents : <span>0 días</span>}
      </div>
      <div
        className="alert alert-info m-1 etiqueta-info"
        style={{ padding: "3px", width: "100%" }}
      >
        <span>Semanas: {semanas}</span>
      </div>
    </>

  );
};

export default Contador;
