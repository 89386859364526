import React from "react";
import "./App.css";
import Contador from "./Components/Contador";

function App() {
  const fechas_nacimiento = {
    senya: { name: "Senya", fecha: "20/04/2024" },
  };

  return (
    <div className="App">
      .: Tempus senya :.
      <img src="img/senya.jpg" className="imagen" />
      {Object.keys(fechas_nacimiento).map((nombre) => (
        <Contador
          key={nombre}
          nombre={fechas_nacimiento[nombre].name}
          fecha={fechas_nacimiento[nombre].fecha}
        />
      ))}
    </div>
  );
}

export default App;
